import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SEOService {
  private suffix = " - TrackConnection";

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private dom
  ) {}

  setSuffix(suffix: string) {
    this.suffix = suffix;
  }

  getSuffix(): string {
    return this.suffix;
  }

  injectCanonical() {
    const link: HTMLLinkElement = this.dom.createElement("link");
    link.setAttribute("rel", "canonical");
    this.dom.head.appendChild(link);
    link.setAttribute("href", this.dom.URL);
  }

  updateTitle(title: string) {
    this.title.setTitle(title + this.suffix);
    this.meta.updateTag({ property: "og:title", content: title + this.suffix });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: "og:url", content: url });
  }

  updateOgImage(url: string) {
    this.meta.updateTag({ property: "og:image", content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: "description", content: desc });
    this.meta.updateTag({ property: "og:description", content: desc });
  }
}
