import { ArtistInterface } from "src/app/core/services/artist.service";
import { environment } from "src/environments/environment";

import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-section-last-artists",
  templateUrl: "./section-last-artists.component.html",
  styleUrls: ["./section-last-artists.component.sass"],
})
export class SectionLastArtistsComponent {
  boombox_invitation = environment.boombox_invitation;
  app_name = environment.app_name;
  discord_invitation = environment.discord_invitation;
  backoffice_endpoint = environment.backoffice_endpoint;

  underline_text = "indépendants";
  private underline_i = 0;
  private underline_texts: Array<string> = [
    "indépendants",
    "talentueux",
    "prometteurs",
  ];

  @Input() artists: Array<ArtistInterface> = [];
  @ViewChild("artistsList", { static: true }) private _ulRef: ElementRef;

  constructor(private router: Router) {}

  ngOnInit(): void {
    // Edit underline text each second
    setInterval(() => {
      if (this.underline_i < this.underline_texts.length - 1) {
        this.underline_i++;
      } else {
        this.underline_i = 0;
      }
      this.underline_text = this.underline_texts[this.underline_i];
    }, 2000);

    setInterval(() => {
      if (this._ulRef.nativeElement.scrollTop > 1700) {
        this._ulRef.nativeElement.scrollTop = 0;
      } else {
        this._ulRef.nativeElement.scrollTop += 500 + 20;
      }
    }, 3000);
  }

  getAvatarLink(uuid: string): string {
    return environment.api + "/files/" + uuid;
  }

  goto(pseudo: string) {
    this.router.navigate([`artistes/${pseudo}`]);
  }
}
