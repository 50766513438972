<section class="padding-top-150 padding-bottom-100">
  <div class="container">
    <div class="icon">
      <img
        src="/assets/images/404.png.webp"
        alt="Logo d'erreur 404"
        width="150"
        height="150"
      />
    </div>

    <div class="message">
      <h3>404 Not Found</h3>
      <span
        >Oups, il semblerait que ce que vous cherchiez ne soit pas ou plus
        disponible. Veuillez réssayer...</span
      >
    </div>
  </div>
</section>
