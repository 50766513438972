import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { ApiOutputInterface } from "../interfaces/api.interface";
import { EditableInterface } from "../interfaces/editable.interface";
import { LoadersInterface } from "../interfaces/loading.interface";

export interface GenreInterface extends EditableInterface, LoadersInterface {
  uuid: string;
  name: string;
}

interface GenresHttpInterface extends ApiOutputInterface {
  genres: Array<GenreInterface>;
}

@Injectable({
  providedIn: "root",
})
export class GenreService {
  constructor(private http: HttpClient) {}

  public list(): Promise<Array<GenreInterface>> {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + "/genres").subscribe(
        (output: GenresHttpInterface) => {
          return resolve(output.genres);
        },
        (err) => {
          return reject(err);
        }
      );
    });
  }
}
