<article class="media-item" (click)="openMedia(media)">
  <div class="media-item-content">
    <div class="media-image">
      <img
        class="card-bordered card-shadowed"
        alt="Pochette de {{ media.name }}"
        [src]="getImageLink(media.cover.uuid)"
        *ngIf="media.cover"
      />
      <img
        class="card-bordered card-shadowed"
        alt="Pochette de {{ media.name }}"
        src="/assets/images/cover_default.png.webp"
        *ngIf="!media.cover"
      />
    </div>
    <div class="media-title">
      <h5>{{ media.name }}</h5>
    </div>
    <div class="media-info">
      <a
        *ngIf="media.link_youtube"
        title="Youtube"
        class="btn-platform"
        href="{{ link_service.generate('media', media.uuid, 'youtube') }}"
        rel="nofollow, noindex"
        target="_blank"
      >
        <i class="fa fa-youtube"></i>
      </a>
      <a
        *ngIf="media.link_spotify"
        title="Spotify"
        class="btn-platform"
        href="{{ link_service.generate('media', media.uuid, 'spotify') }}"
        rel="nofollow, noindex"
        target="_blank"
      >
        <i class="fa fa-spotify"></i>
      </a>
      <a
        *ngIf="media.link_deezer"
        title="Deezer"
        class="btn-platform"
        href="{{ link_service.generate('media', media.uuid, 'deezer') }}"
        rel="nofollow, noindex"
        target="_blank"
      >
        <i class="fa fa-music"></i>
      </a>
      <a
        *ngIf="media.link_soundcloud"
        title="Soundcloud"
        class="btn-platform"
        href="{{ link_service.generate('media', media.uuid, 'soundcloud') }}"
        rel="nofollow, noindex"
        target="_blank"
      >
        <i class="fa fa-soundcloud"></i>
      </a>
      <a
        *ngIf="media.link_itunes"
        title="iTunes"
        class="btn-platform"
        href="{{ link_service.generate('media', media.uuid, 'itunes') }}"
        rel="nofollow, noindex"
        target="_blank"
      >
        <i class="fa fa-apple"></i>
      </a>
      <a
        *ngIf="media.link_tidal"
        title="Tidal"
        class="btn-platform"
        href="{{ link_service.generate('media', media.uuid, 'tidal') }}"
        rel="nofollow, noindex"
        target="_blank"
      >
        <i class="fa fa-music"></i>
      </a>
      <a
        *ngIf="media.link_bandcamp"
        title="Bandcamp"
        class="btn-platform"
        href="{{ link_service.generate('media', media.uuid, 'bandcamp') }}"
        rel="nofollow, noindex"
        target="_blank"
      >
        <i class="fa fa-bandcamp"></i>
      </a>
    </div>
  </div>
</article>
