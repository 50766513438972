<section class="latest-release">
  <div class="container">
    <div class="main-heading">
      <a routerLink="/artistes">
        <i class="fa fa-plus"></i>
      </a>
      <h2>Nos dernières sorties</h2>

      <div class="pager">
        <a>
          <i class="fa fa-arrow-left"></i>
        </a>
        <a>
          <i class="fa fa-arrow-right"></i>
        </a>
      </div>
    </div>

    <span class="advert" *ngIf="loading"
      >Chargement <i class="fa fa-spin fa-spinner"></i
    ></span>
    <span class="advert" *ngIf="!loading && medium.length <= 0"
      >Aucun son trouvé. Pourquoi pas le vôtre ?</span
    >

    <ng-container *ngIf="!loading">
      <ul>
        <li *ngFor="let media of medium">
          <article (click)="goto(media.owner.pseudo)">
            <img
              [src]="getCoverLink(media.cover.uuid)"
              alt="Pochette de {{ media.name }}"
              height="280"
              width="280"
              *ngIf="media.cover"
            />
            <img
              src="/assets/images/cover_default.png.webp"
              alt="Pochette de {{ media.name }}"
              height="280"
              width="280"
              *ngIf="!media.cover"
            />
            <div class="botm-text">
              <span>{{ media.owner.pseudo }}</span>
              <a>{{ media.name }}</a>
            </div>
          </article>
        </li>
      </ul>
    </ng-container>
  </div>
</section>
