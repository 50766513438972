import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ui-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.sass"],
})
export class LoaderComponent implements OnInit {
  @Input()
  loading = false;

  dotsQty = 0;
  dots = "";

  constructor() {}

  ngOnInit(): void {
    setInterval(() => {
      if (this.dotsQty < 3) {
        this.dotsQty++;
        this.dots = ".".repeat(this.dotsQty);
      } else {
        this.dotsQty = 0;
        this.dots = "";
      }
    }, 800);
  }
}
