<footer>
  <div class="container">
    <div class="row">
      <div class="col col-md-4 col-xs-12">
        <img
          class="img-responsive"
          src="/assets/images/logo.png.webp"
          alt="Logo de {{ app_name }}"
        />

        <p>
          Sur <strong>TrackConnection.net</strong> vous pourrez découvrir des
          jeunes talents musicaux aux genres musicaux et compétences variées.
          Vous trouverez aussi bien des beatmakers que des compositeurs ou
          encore des rappeurs.
        </p>
      </div>

      <div class="col col-md-2 col-xs-6">
        <ul>
          <li>
            <a routerLink="/" routerLinkActive="active">Accueil</a>
          </li>
          <li>
            <a routerLink="/a-propos" routerLinkActive="active">A propos</a>
          </li>
          <li>
            <a routerLink="/artistes" routerLinkActive="active">Artistes</a>
          </li>
          <li>
            <a routerLink="/mixtapes" routerLinkActive="active">Mixtapes</a>
          </li>
        </ul>
      </div>
      <div class="col col-md-3 col-xs-6">
        <ul>
          <li>
            <a href="{{ backoffice_endpoint }}" rel="nofollow, noindex"
              >Se connecter</a
            >
            <i class="fa fa-external-link"></i>
          </li>
          <li>
            <a href="{{ boombox_invitation }}" rel="nofollow, noindex"
              >Inviter la Boombox</a
            >
            <i class="fa fa-external-link"></i>
          </li>
        </ul>
        <ul>
          <li>
            <a href="{{ status_endpoint }}" rel="nofollow, noindex"
              >Etat des services</a
            >
            <i class="fa fa-external-link"></i>
          </li>
        </ul>
      </div>

      <div class="col col-md-3 col-xs-12">
        <a href="{{ discord_invitation }}" class="btn" rel="nofollow, noindex"
          >Nous rejoindre</a
        >

        <br />

        <ui-radio-player *ngIf="showRadio()"></ui-radio-player>
      </div>
    </div>

    <hr />

    <p class="copyright">
      TrackConnection.net - 2023<br />
      -<br />
      Made with <i class="fa fa-heart"></i> by
      <a href="http://hugo.chupin.xyz/">Hugo CHUPIN</a><br />
      Designed by
      <a href="https://www.behance.net/clementgridel">Clément Gridel</a>
    </p>
  </div>
</footer>
