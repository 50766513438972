import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AboutPageComponent } from "./pages/about-page/about-page.component";
import { NotFoundPageComponent } from "./pages/errors/not-found-page/not-found-page.component";
import { ServerErrorPageComponent } from "./pages/errors/server-error-page/server-error-page.component";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { PlaylistPageComponent } from "./pages/playlist-page/playlist-page.component";
// import { ArtistLinksPageComponent } from './pages/links-tree/artist-links/artist-links.component';
import { SearchPlaylistPageComponent } from "./pages/search-playlist-page/search-playlist-page.component";

const routes: Routes = [
  {
    path: "",
    component: HomePageComponent,
  },
  {
    path: "a-propos",
    component: AboutPageComponent,
  },
  {
    path: "artistes/:artist/playlists/:playlist",
    component: PlaylistPageComponent,
  },
  {
    path: "artistes",
    loadChildren: () =>
      import("./pages/artist-page/artist-page.module").then(
        (m) => m.ArtistPageModule
      ),
  },
  {
    path: "a",
    loadChildren: () =>
      import("./pages/links-tree/links-tree.module").then(
        (m) => m.LinksTreeModule
      ),
  },
  {
    path: "mixtapes",
    component: SearchPlaylistPageComponent,
  },
  {
    path: "404",
    component: NotFoundPageComponent,
  },
  {
    path: "500",
    component: ServerErrorPageComponent,
  },
  {
    path: "**",
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
