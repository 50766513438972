import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { ApiOutputInterface } from "../interfaces/api.interface";
import { GPSInterface } from "../interfaces/gps.interface";
import { GenreInterface } from "./genre.service";
import { ImageInterface } from "./image.service";
import { MediaInterface } from "./media.service";
import { RoleInterface } from "./role.service";

export interface ArtistInterface {
  uuid: string;
  email: string;
  pseudo: string;
  avatar: ImageInterface;
  banner: ImageInterface;
  short_bio: string;
  long_bio: string;
  genre?: GenreInterface;
  role?: RoleInterface;

  location: string;
  location_long: number;
  location_lat: number;

  website: string;
  social_youtube: string;
  social_spotify: string;
  social_deezer: string;
  social_soundcloud: string;
  social_twitter: string;
  social_itunes: string;
  social_bandcamp: string;
  social_tidal: string;
  social_instagram: string;
  social_discord: string;

  power: number;
  stats?: ArtistStatsInterface;
  views?: Array<GPSInterface>;
  created_at: number;
}

export interface ArtistActivityInterface {
  action: string;
  medium?: MediaInterface;
  author: ArtistInterface;
  isDisplayed: boolean;
  date: number;
}

export interface ArtistStatsInterface {
  [propName: string]: number;
}

export interface ArtistHttpInterface extends ApiOutputInterface {
  artist: ArtistInterface;
  stats: ArtistStatsInterface;
  views: Array<GPSInterface>;
}

export interface ArtistActivitiesHttpInterface extends ApiOutputInterface {
  activities: Array<ArtistActivityInterface>;
}

interface ArtistsHttpInterface extends ApiOutputInterface {
  artists: Array<ArtistInterface>;
  pages: number;
}

@Injectable({
  providedIn: "root",
})
export class ArtistService {
  constructor(private http: HttpClient) {}

  public list(
    page = 1,
    sort = "DDESC",
    nb = 20,
    genre?: string,
    role?: string,
    pseudo?: string
  ): Promise<ArtistsHttpInterface> {
    return new Promise((resolve, reject) => {
      const params = {
        page: page.toString(),
        sort: sort,
        nb: nb.toString(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
      if (genre) params.genre = genre;
      if (role) params.role = role;
      if (pseudo) params.pseudo = pseudo;

      this.http
        .get(environment.api + "/public/artists", {
          params: params,
        })
        .subscribe(
          (output: ArtistsHttpInterface) => {
            return resolve(output);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public fetch(uuid: string, use_pseudo = false): Promise<ArtistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.api + "/public/artists/" + uuid, {
          params: { use_pseudo: use_pseudo.toString() },
        })
        .subscribe(
          (output: ArtistHttpInterface) => {
            output.artist.stats = output.stats;
            output.artist.views = output.views;
            return resolve(output.artist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public activities(uuid: string): Promise<Array<ArtistActivityInterface>> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.api + "/public/artists/" + uuid + "/activities")
        .subscribe(
          (output: ArtistActivitiesHttpInterface) => {
            return resolve(output.activities);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
