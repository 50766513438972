import {
  ArtistInterface,
  ArtistService,
} from "src/app/core/services/artist.service";
import { MediaInterface } from "src/app/core/services/media.service";
import {
  PlaylistInterface,
  PlaylistService,
} from "src/app/core/services/playlist.service";
import { SEOService } from "src/app/core/services/seo.service";
import { environment } from "src/environments/environment";

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

import { LinkService } from "../../core/services/link.service";

@Component({
  selector: "app-playlist-page",
  templateUrl: "./playlist-page.component.html",
  styleUrls: ["./playlist-page.component.sass"],
})
export class PlaylistPageComponent implements OnInit {
  @ViewChild("banner") banner: ElementRef;
  artist_pseudo: string;
  playlist_name: string;
  playlist: PlaylistInterface = {} as PlaylistInterface;
  artist: ArtistInterface = {} as ArtistInterface;
  showcased: MediaInterface;
  loading = false;

  constructor(
    private seo: SEOService,
    private artist_service: ArtistService,
    private playlist_service: PlaylistService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public link_service: LinkService
  ) {}

  ngOnInit(): void {
    this.artist_pseudo = this.route.snapshot.paramMap.get("artist");
    this.playlist_name = this.route.snapshot.paramMap.get("playlist");

    const timeoutId = setTimeout(() => {
      this.loading = true;
    }, environment.loading_delay);

    this.playlist_service.fetch(this.playlist_name, this.artist_pseudo).then(
      (playlist) => {
        this.playlist = playlist;

        // Set SEO tags
        this.seo.updateTitle(this.playlist.name);
        if (this.playlist.cover) {
          this.seo.updateOgImage(this.getImageLink(this.playlist.cover.uuid));
        }
        this.seo.injectCanonical();

        // Set page title
        this.artist_service.fetch(this.playlist.owner.uuid).then(
          (artist) => {
            clearTimeout(timeoutId);
            this.artist = artist;

            // Set user custom banner
            if (this.artist.banner) {
              this.banner.nativeElement.style[
                "background-image"
              ] = `url('${this.getImageLink(this.artist.banner.uuid)}')`;
            }

            this.loading = false;
          },
          () => {
            clearTimeout(timeoutId);
            this.router.navigate(["404"]);
            this.loading = false;
          }
        );
      },
      () => {
        clearTimeout(timeoutId);
        this.router.navigate(["404"]);
        this.loading = false;
      }
    );
  }

  getImageLink(uuid: string): string {
    return environment.api + "/files/" + uuid;
  }

  goto(pseudo: string) {
    this.router.navigate([`artistes/${pseudo}`]);
  }

  open(media: MediaInterface) {
    const url = this.link_service.toLink(media);
    if (url) {
      this.router.navigate([]).then(() => {
        window.open(url, "_blank");
      });
    }
  }
}
