<!-- Bnr Head -->
<section class="bnr-head" parallax [config]="{ initialValue: 0, ratio: 0.6 }">
  <div class="container">
    <div id="description">
      <h1 class="animate__animated animate__backInLeft">
        Nouveaux artistes <span class="underline">{{ underline_text }}</span>
      </h1>
      <p class="animate__animated animate__slideInLeft">
        Sur {{ app_name }}, vous pourrez découvrir des jeunes talents musicaux
        aux genres musicaux et compétences variées. Vous trouverez aussi bien
        des beatmakers que des compositeurs ou encore des rappeurs.
      </p>
      <a
        href="{{ discord_invitation }}"
        class="btn animate__animated animate__fadeInUp"
        rel="nofollow, noindex"
        >Nous rejoindre</a
      >
      <a
        href="{{ backoffice_endpoint }}"
        class="btn btn-trans animate__animated animate__fadeInUp animate__delay-1s"
        rel="nofollow, noindex"
        >Se connecter <i class="fa fa-external-link"></i
      ></a>
      <a
        href="{{ boombox_invitation }}"
        class="btn btn-trans animate__animated animate__fadeInUp animate__delay-1s"
        rel="nofollow, noindex"
        >Inviter la Boombox <i class="fa fa-external-link"></i
      ></a>
    </div>

    <div id="artists">
      <i
        class="fa fa-mouse-pointer animate__animated animate__shakeY animate__infinite animate__slower"
      ></i>
      <h2>Derniers artistes</h2>

      <ul #artistsList>
        <li *ngFor="let artist of artists" (click)="goto(artist.pseudo)">
          <img
            alt="Avatar de {{ artist.pseudo }}"
            [src]="getAvatarLink(artist.avatar.uuid)"
            alt="Avatar de {{ artist.pseudo }}"
            width="500"
            height="500"
            *ngIf="artist.avatar"
          />
          <img
            alt="Avatar de {{ artist.pseudo }}"
            src="/assets/images/avatar_default.png.webp"
            alt="Avatar de {{ artist.pseudo }}"
            width="600"
            height="600"
            *ngIf="!artist.avatar"
          />
        </li>
      </ul>
    </div>
  </div>
</section>
