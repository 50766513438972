import { environment } from "src/environments/environment";

import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "ui-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"],
})
export class HeaderComponent {
  opened = false;
  app_name = environment.app_name;
  discord_invitation = environment.discord_invitation;
  backoffice_endpoint = environment.backoffice_endpoint;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  toggle() {
    this.opened = !this.opened;
  }

  getUrl(): string {
    return this.router.url;
  }

  shouldDisplay(): boolean {
    return this.router.url.indexOf("/a/") < 0;
  }

  showRadio() {
    return environment.webradio_enable;
  }

  isArtistUrl(): boolean {
    return (
      this.router.url.indexOf("/artistes/") >= 0 &&
      this.router.url.indexOf("/playlists/") < 0
    );
  }

  isMixtapeUrl(): boolean {
    return this.router.url.indexOf("/playlists/") >= 0;
  }
}
