<!-- Wrap -->
<div id="wrap">
  <!-- header -->
  <ui-header></ui-header>

  <!-- Content -->
  <div id="content">
    <router-outlet></router-outlet>
  </div>

  <!-- footer -->
  <ui-footer></ui-footer>
</div>
