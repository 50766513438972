import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { MediaInterface } from "./media.service";

export enum EType {
  MEDIA = "media",
  ARTIST = "artist",
}

export enum ELink {
  WEBSITE = "website",
  DEEZER = "deezer",
  SPOTIFY = "spotify",
  SOUNDCLOUD = "soundcloud",
  YOUTUBE = "youtube",
  TWITTER = "twitter",
  DISCORD = "discord",
  INSTAGRAM = "instagram",
  ITUNES = "itunes",
  BANDCAMP = "bandcamp",
  TIDAL = "tidal",
}

@Injectable({
  providedIn: "root",
})
export class LinkService {
  constructor() {}

  public generate(
    type: EType | string,
    uuid: string,
    link?: ELink | string
  ): string {
    if (link) {
      return `${environment.api}/l/${type}/${uuid}/${link}`;
    } else {
      return `${environment.api}/l/${type}/${uuid}`;
    }
  }

  public toLink(item: MediaInterface): string {
    let url: string;

    if (item.link_youtube) {
      url = this.generate(EType.MEDIA, item.uuid, ELink.YOUTUBE);
    } else if (item.link_soundcloud) {
      url = this.generate(EType.MEDIA, item.uuid, ELink.SOUNDCLOUD);
    } else if (item.link_spotify) {
      url = this.generate(EType.MEDIA, item.uuid, ELink.SPOTIFY);
    } else if (item.link_deezer) {
      url = this.generate(EType.MEDIA, item.uuid, ELink.DEEZER);
    } else if (item.link_itunes) {
      url = this.generate(EType.MEDIA, item.uuid, ELink.ITUNES);
    } else if (item.link_tidal) {
      url = this.generate(EType.MEDIA, item.uuid, ELink.TIDAL);
    } else if (item.link_bandcamp) {
      url = this.generate(EType.MEDIA, item.uuid, ELink.BANDCAMP);
    }

    return url;
  }

  public getImageLink(uuid: string): string {
    return environment.api + "/files/" + uuid;
  }

  public getLinktreeLink(uuid: string): string {
    return environment.linktree_endpoint + "/a/" + uuid;
  }

  public openMedia(media: MediaInterface): void {
    const url = this.toLink(media);
    if (url) window.open(url, "_blank");
  }
}
