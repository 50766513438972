import { CarouselModule } from "ngx-owl-carousel-o";
import { ParallaxModule } from "ngx-parallax";

import { ClipboardModule } from "@angular/cdk/clipboard";
import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { LastArtistsComponent } from "./components/last-artists/last-artists.component";
import { LastMediumComponent } from "./components/last-medium/last-medium.component";
import { SectionLastArtistsComponent } from "./components/section-last-artists/section-last-artists.component";
import { SectionLastTracksComponent } from "./components/section-last-tracks/section-last-tracks.component";
import { HttpConfigInterceptor } from "./httpconfig.interceptor";
import { AboutPageComponent } from "./pages/about-page/about-page.component";
import { NotFoundPageComponent } from "./pages/errors/not-found-page/not-found-page.component";
import { ServerErrorPageComponent } from "./pages/errors/server-error-page/server-error-page.component";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { PlaylistPageComponent } from "./pages/playlist-page/playlist-page.component";
import { SearchPlaylistPageComponent } from "./pages/search-playlist-page/search-playlist-page.component";
import { SharedModule } from "./shared/shared.module";
import { NgxMatomoModule } from 'ngx-matomo-client';
import { NgxMatomoRouterModule } from 'ngx-matomo-client';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LastMediumComponent,
    LastArtistsComponent,

    AboutPageComponent,

    PlaylistPageComponent,

    SearchPlaylistPageComponent,
    HeaderComponent,
    FooterComponent,

    NotFoundPageComponent,

    ServerErrorPageComponent,
    SectionLastArtistsComponent,
    SectionLastTracksComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    AppRoutingModule,
    ParallaxModule,
    ClipboardModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgxMatomoModule.forRoot({ trackerUrl: 'https://matomo.chupin.xyz/', siteId: '4' }),
    NgxMatomoRouterModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "fr-FR" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
