<!-- loader -->
<ui-loader [(loading)]="loading"></ui-loader>

<section
  class="main-bnr"
  #banner
  parallax
  [config]="{ initialValue: -20, ratio: 0.6 }"
></section>

<section class="block-about">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-xs-12">
        <a (click)="goto(artist.pseudo)">
          <i class="fa fa-caret-left"></i> Retour à la page de l'artiste
        </a>
      </div>

      <div class="col-md-4 col-xs-12">
        <div class="playlist-details card-bordered-larger card-shadowed">
          <div class="playlist-cover">
            <img
              class="img-responsive"
              alt="Pochette de {{ playlist.name }}"
              [src]="getImageLink(playlist.cover.uuid)"
              *ngIf="playlist.cover"
            />
            <img
              class="img-responsive"
              alt="Pochette de {{ playlist.name }}"
              src="/assets/images/cover_default.png.webp"
              *ngIf="!playlist.cover"
            />
          </div>

          <div class="playlist-about">
            <div class="playlist-author visible-xs-inline-block">
              <span class="type">EP</span>
              <h1>{{ playlist_name }}</h1>
              <span class="author">
                par
                <a (click)="goto(artist.pseudo)"
                  >{{ artist_pseudo }}
                  <img
                    alt="Avatar de {{ artist.pseudo }}"
                    [src]="getImageLink(artist.avatar.uuid)"
                    *ngIf="artist.avatar"
                  />
                  <img
                    alt="Avatar de {{ artist.pseudo }}"
                    src="/assets/images/avatar_default.png.webp"
                    *ngIf="!artist.avatar"
                  />
                </a>
              </span>
              <span class="footer">
                {{ playlist.created_at * 1000 | date : "yyyy" }}
                <ng-container *ngIf="playlist.medium"
                  >- {{ playlist.medium.length }} titres
                </ng-container>
              </span>
            </div>

            <hr class="visible-xs-block" />

            <table class="table">
              <tbody>
                <tr
                  class="media"
                  *ngFor="let media of playlist.medium"
                  (click)="open(media)"
                >
                  <td class="title">{{ media.name }}</td>
                  <td class="links">
                    <a
                      class="btn-platform"
                      *ngIf="media.link_youtube"
                      rel="nofollow, noindex"
                      href="{{
                        link_service.generate('media', media.uuid, 'youtube')
                      }}"
                      target="_blank"
                      title="Youtube"
                    >
                      <i class="fa fa-youtube"></i>
                    </a>
                    <a
                      class="btn-platform"
                      *ngIf="media.link_spotify"
                      rel="nofollow, noindex"
                      href="{{
                        link_service.generate('media', media.uuid, 'spotify')
                      }}"
                      target="_blank"
                      title="Spotify"
                    >
                      <i class="fa fa-spotify"></i>
                    </a>
                    <a
                      class="btn-platform"
                      *ngIf="media.link_deezer"
                      rel="nofollow, noindex"
                      href="{{
                        link_service.generate('media', media.uuid, 'deezer')
                      }}"
                      target="_blank"
                      title="Deezer"
                    >
                      <i class="fa fa-music"></i>
                    </a>
                    <a
                      class="btn-platform"
                      *ngIf="media.link_soundcloud"
                      rel="nofollow, noindex"
                      href="{{
                        link_service.generate('media', media.uuid, 'soundcloud')
                      }}"
                      target="_blank"
                      title="Soundcloud"
                    >
                      <i class="fa fa-soundcloud"></i>
                    </a>
                    <a
                      class="btn-platform"
                      *ngIf="media.link_itunes"
                      rel="nofollow, noindex"
                      href="{{
                        link_service.generate('media', media.uuid, 'itunes')
                      }}"
                      target="_blank"
                      title="iTunes"
                    >
                      <i class="fa fa-apple"></i>
                    </a>
                    <a
                      class="btn-platform"
                      *ngIf="media.link_tidal"
                      rel="nofollow, noindex"
                      href="{{
                        link_service.generate('media', media.uuid, 'tidal')
                      }}"
                      target="_blank"
                      title="Tidal"
                    >
                      <i class="fa fa-music"></i>
                    </a>
                    <a
                      class="btn-platform"
                      *ngIf="media.link_bandcamp"
                      rel="nofollow, noindex"
                      href="{{
                        link_service.generate('media', media.uuid, 'bandcamp')
                      }}"
                      target="_blank"
                      title="Bandcamp"
                    >
                      <i class="fa fa-bandcamp"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-8 col-md-push-4 col-xs-12">
        <div class="playlist-author visible-lg-inline-block">
          <span class="type">EP</span>
          <h1>{{ playlist_name }}</h1>
          <span class="author">
            par
            <a (click)="goto(artist.pseudo)"
              >{{ artist_pseudo }}
              <img
                alt="Avatar de {{ artist.pseudo }}"
                [src]="getImageLink(artist.avatar.uuid)"
                *ngIf="artist.avatar"
              />
              <img
                alt="Avatar de {{ artist.pseudo }}"
                src="/assets/images/avatar_default.png.webp"
                *ngIf="!artist.avatar"
              />
            </a>
          </span>
          <span class="footer">
            {{ playlist.created_at * 1000 | date : "yyyy" }}
            <ng-container *ngIf="playlist.medium"
              >- {{ playlist.medium.length }} titres
            </ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="small-bnr">
  <div class="container">
    <div class="cover">
      <img alt="Pochette de {{ playlist.name }}" [src]="getImageLink(playlist.cover.uuid)" *ngIf="playlist.cover" />
<img alt="Pochette de {{ playlist.name }}" src="/assets/images/cover_default.png.webp" *ngIf="!playlist.cover" />
</div>
<div class="infos">
  <span class="type">EP</span>
  <h1>{{ playlist_name }}</h1>
  <span class="author">
    par <a (click)="goto(artist.pseudo)">{{ artist_pseudo }}
      <img alt="Avatar de {{ artist.pseudo }}" [src]="getImageLink(artist.avatar.uuid)" *ngIf="artist.avatar" />
      <img alt="Avatar de {{ artist.pseudo }}" src="/assets/images/avatar_default.png.webp" *ngIf="!artist.avatar" />
    </a>
  </span>
  <span class="footer">
    {{ playlist.created_at * 1000 | date: "yyyy" }} <ng-container *ngIf="playlist.medium">-
      {{ playlist.medium.length}} titres
    </ng-container>
  </span>
</div>
</div>
</section>

<section class="padding-top-80 padding-bottom-80">
  <div class="container">

    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Titre</th>
          <th class="links">Sources</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let media of playlist.medium; let i = index" (click)="open(media)">
          <td>{{ i+1 }}</td>
          <td>{{media.name}}</td>
          <td class="links">
            <a *ngIf="media.link_youtube" href="{{ link_service.generate('media', media.uuid, 'youtube') }}"
              target="_blank" title="Youtube">
              <i class="fa fa-youtube"></i>
            </a>
            <a *ngIf="media.link_spotify" href="{{ link_service.generate('media', media.uuid, 'spotify') }}"
              target="_blank" title="Spotify">
              <i class="fa fa-spotify"></i>
            </a>
            <a *ngIf="media.link_deezer" href="{{ link_service.generate('media', media.uuid, 'deezer') }}"
              target="_blank" title="Deezer">
              <i class="fa fa-music"></i>
            </a>
            <a *ngIf="media.link_soundcloud" href="{{ link_service.generate('media', media.uuid, 'soundcloud') }}"
              target="_blank" title="Soundcloud">
              <i class="fa fa-soundcloud"></i>
            </a>
            <a *ngIf="media.link_itunes" href="{{ link_service.generate('media', media.uuid, 'itunes') }}"
              target="_blank" title="iTunes">
              <i class="fa fa-apple"></i>
            </a>
            <a *ngIf="media.link_tidal" href="{{ link_service.generate('media', media.uuid, 'tidal') }}" target="_blank"
              title="Tidal">
              <i class="fa fa-music"></i>
            </a>
            <a *ngIf="media.link_bandcamp" href="{{ link_service.generate('media', media.uuid, 'bandcamp') }}"
              target="_blank" title="Bandcamp">
              <i class="fa fa-bandcamp"></i>
            </a>

          </td>
        </tr>
      </tbody>
    </table>

  </div>
</section> -->
