<header *ngIf="shouldDisplay()">
  <div class="container">
    <!-- Logo -->
    <div class="logo">
      <a routerLink="/" routerLinkActive="active">
        <img
          class="img-responsive"
          src="/assets/images/logo.png.webp"
          height="300"
          width="39"
          alt="Logo de {{ app_name }}"
        />
      </a>
    </div>
    <div class="navbar-button">
      <button
        type="button"
        aria-label="Menu mobile"
        [class.opened]="opened"
        (click)="toggle()"
      >
        <i class="fa fa-bars"></i>
      </button>
    </div>
    <!-- Navigation -->
    <nav class="navbar" [class.opened]="opened" (click)="toggle()">
      <!-- NAV -->
      <ul class="nav ownmenu">
        <li [class.active]="getUrl() == '/'">
          <a routerLink="../" routerLinkActive="active">Accueil</a>
        </li>
        <li [class.active]="getUrl() == '/a-propos'">
          <a routerLink="a-propos" routerLinkActive="active">A propos</a>
        </li>
        <li [class.active]="getUrl() == '/artistes' || isArtistUrl()">
          <a routerLink="artistes" routerLinkActive="active">Artistes</a>
        </li>
        <li [class.active]="getUrl() == '/mixtapes' || isMixtapeUrl()">
          <a routerLink="mixtapes" routerLinkActive="active">Mixtapes</a>
        </li>
      </ul>
    </nav>
    <div class="radio">
      <ui-radio-player *ngIf="showRadio()"></ui-radio-player>
    </div>
  </div>
</header>
