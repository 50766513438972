import {
  GenreInterface,
  GenreService,
} from "src/app/core/services/genre.service";
import { PlaylistInterface } from "src/app/core/services/playlist.service";
import { SEOService } from "src/app/core/services/seo.service";
import { environment } from "src/environments/environment";

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PlaylistService } from "../../core/services/playlist.service";

@Component({
  selector: "app-search-playlist-page",
  templateUrl: "./search-playlist-page.component.html",
  styleUrls: ["./search-playlist-page.component.sass"],
})
export class SearchPlaylistPageComponent implements OnInit {
  playlists: Array<PlaylistInterface> = [];
  genres: Array<GenreInterface> = [];
  genre = "all";
  type = "all";
  name: string;
  sort = "NASC";
  pages = 1;
  pageMax = 1;
  loading = false;

  constructor(
    private seo: SEOService,
    private playlist_service: PlaylistService,
    private genre_service: GenreService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // if (this.router.url == "/mixtapes") {
    //   this.type = "2";
    // }

    // Set SEO tags
    this.seo.updateTitle("Découvrir nos sons");
    this.seo.injectCanonical();

    // Check for GET params
    this.route.queryParams.subscribe((params) => {
      this.type = params.type ? params.type : "all";
      this.genre = params.genre ? params.genre : "all";
      this.sort = params.sort ? params.sort : "NASC";
      this.name = params.name;
      this.update();
    });

    Promise.all([this.genre_service.list()]).then(([genres]) => {
      this.genres = genres;
    });
  }

  on_next(): void {
    this.pages = this.pages + 1;
    this.update();
  }
  on_previous(): void {
    this.pages = this.pages - 1;
    this.update();
  }
  goto(pseudo: string) {
    this.router.navigate([`artistes/${pseudo}`]);
  }
  getAvatarLink(uuid: string): string {
    return environment.api + "/files/" + uuid;
  }
  search(): void {
    this.pages = 1;
    this.router.navigate(["/mixtapes"], {
      queryParams: {
        genre: this.genre,
        type: this.type,
        name: this.name,
        sort: this.sort,
      },
    });
    // this.update();
  }

  update(): Promise<void> {
    return new Promise(() => {
      const timeoutId = setTimeout(() => {
        this.loading = true;
      }, environment.loading_delay);
      this.playlist_service
        .list(1, 20, this.sort, this.genre, this.type, undefined, this.name)
        .then(
          (output) => {
            clearTimeout(timeoutId);
            this.playlists = output.playlists;
            this.pageMax = output.pages;
            this.loading = false;
          },
          () => {
            clearTimeout(timeoutId);
            this.loading = false;
          }
        );
    });
  }
}
