import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem("APP_TOKEN");

    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
    }

    if (
      !request.headers.has("Content-Type") &&
      !(request.body && request.body.toString() === "[object FormData]")
    ) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json"),
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    // return next.handle(request);
    return next.handle(request).pipe(
      tap(
        (event) => this.handleResponse(request, event),
        (error) => this.handleError(request, error)
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleResponse(req: HttpRequest<any>, event) {
    if (event instanceof HttpResponse) {
      if (event.status !== 200) {
        this.handleError(req, event);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(req: HttpRequest<any>, error) {
    if (environment.production) return;

    console.log("[" + error.status + "] " + req.method + ": " + req.url);
    console.log(error);

    if (error.name === "HttpErrorResponse" && error.error.error) {
      console.error(error.error.error);
    }
  }
}
