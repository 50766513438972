export const environment = {
  production: true,

  app_name: "TrackConnection.net",
  api: "https://api.trackconnection.paas.chupin.xyz",
  seo_suffix: "- TrackConnection",

  loading_delay: 1000,

  webradio_enable: false,

  linktree_endpoint: "https://www.trackconnection.paas.chupin.xyz",

  backoffice_endpoint: "https://my.trackconnection.paas.chupin.xyz",
  webradio_endpoint:
    "https://webradio.trackconnection.paas.chupin.xyz/public/trackconnection",
  webradio_api_endpoint: "https://webradio.trackconnection.paas.chupin.xyz/api",
  status_endpoint: "https://uptime.home.chupin.xyz/status/trackconnection",
  boombox_topgg_endpoint: "https://top.gg/fr/bot/928344372242677831",
  boombox_invitation:
    "https://discord.com/oauth2/authorize?client_id=928344372242677831&scope=bot&permissions=274881121344",
  discord_invitation: "https://discord.gg/B6uNT3N",
};
