<div class="radio">
  <div class="radio-icon">
    <button
      type="button"
      id="play"
      aria-label="Lecture"
      *ngIf="!isPlaying()"
      (click)="play()"
    >
      <i class="fa fa-play"></i>
    </button>
    <button
      type="button"
      id="stop"
      aria-label="Stop"
      *ngIf="isPlaying()"
      (click)="stop()"
    >
      <i class="fa fa-stop"></i>
    </button>
  </div>
  <div class="radio-current">
    <span class="radio-artist">{{ getCurrent().artist }}</span>
    {{ getCurrent().title }}
  </div>
</div>

<audio #webplayer></audio>
