<!-- loader -->
<ui-loader [(loading)]="loading"></ui-loader>

<!-- MAIN BANNER -->
<section class="sub-bnr" parallax [config]="{ initialValue: 0, ratio: 0.6 }">
  <div class="position-center-center">
    <div class="container animate__fadeInDown animate__animated">
      <h1>Découvrez nos mixtapes</h1>
    </div>
  </div>
</section>

<!-- Search Bar -->
<div class="search-pro">
  <div class="map-search">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="map-search-fields">
            <div class="field">
              <input
                type="text"
                (keyup.enter)="update()"
                [(ngModel)]="name"
                [(disabled)]="loading"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Nom"
              />
            </div>
            <div class="field">
              <select
                [(ngModel)]="type"
                [(disabled)]="loading"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="all">Tout les types</option>
                <option value="0">Playlists</option>
                <!--<option value="1">Albums</option>-->
                <option value="2">Mixtapes</option>
                <option value="3">EPs</option>
              </select>
            </div>
            <div class="field">
              <select
                [(ngModel)]="genre"
                [(disabled)]="loading"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="all">Tout les genres</option>
                <option *ngFor="let genre of genres" value="{{ genre.uuid }}">
                  {{ genre.name }}
                </option>
              </select>
            </div>
            <div class="field">
              <select
                [(ngModel)]="sort"
                [(disabled)]="loading"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="DDESC">
                  Date d'inscription (plus récent d'abord)
                </option>
                <option value="DASC">
                  Date d'inscription (plus ancien d'abord)
                </option>
                <option value="NASC">Nom (ordre alphabétique)</option>
                <option value="DASC">Nom (ordre anti-alphabétique)</option>
              </select>
            </div>
          </div>
          <div class="search-button">
            <button (click)="search()" [disabled]="loading">Rechercher</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- About -->
<section
  class="portfolio port-wrap discover-music padding-top-80 padding-bottom-80 filter-index about-page"
>
  <div class="container">
    <div class="row col-4">
      <!--<span class="advert" *ngIf="loading">Chargement <i class="fa fa-spin
          fa-spinner"></i></span>-->
      <span class="advert" *ngIf="!loading && playlists.length <= 0"
        >Aucune playlist trouvée. Pourquoi pas la vôtre ?</span
      >
    </div>

    <!-- PORTFOLIO ITEMS -->
    <div class="items row" *ngIf="!loading">
      <div class="col-xs-6 col-md-3" *ngFor="let playlist of playlists">
        <media-preview [media]="playlist"> </media-preview>
      </div>
    </div>

    <!--<div class="pager row col-4">
      <ui-pager (next)="on_next()" (previous)="on_previous()" [pageMax]="pageMax"></ui-pager>
    </div>-->
  </div>
</section>
