import { OwlOptions } from "ngx-owl-carousel-o";
import { SEOService } from "src/app/core/services/seo.service";
import { environment } from "src/environments/environment";

import { Component, OnInit } from "@angular/core";

interface ScreenshotInterface {
  path: string;
  title: string;
  id: string;
}

@Component({
  selector: "app-about-page",
  templateUrl: "./about-page.component.html",
  styleUrls: ["./about-page.component.sass"],
})
export class AboutPageComponent implements OnInit {
  private screenshots_ary: Array<ScreenshotInterface> = [
    {
      id: "screen-login",
      path: "/assets/screenshots/my-alltune-fr-login.png.webp",
      title: "Connectez sur my.trackconnection.net",
    },
    {
      id: "screen-songs",
      path: "/assets/screenshots/my-alltune-fr-songs.png.webp",
      title: "Listez vos tubes sur my.trackconnection.net",
    },
    {
      id: "screen-songs-edition",
      path: "/assets/screenshots/my-alltune-fr-songs-edition.png.webp",
      title: "Editez votre tube sur my.trackconnection.net",
    },
    {
      id: "screen-playlists-edition",
      path: "/assets/screenshots/my-alltune-fr-playlists-edition.png.webp",
      title: "Editez votre playlist sur my.trackconnection.net",
    },
    {
      id: "screen-webradio",
      path: "/assets/screenshots/webradio-alltune-fr-public.png.webp",
      title: "Ecoutez vos tubes sur webradio.trackconnection.net",
    },
    {
      id: "screen-my",
      path: "/assets/screenshots/webradio-alltune-fr-admin.png.webp",
      title: "Gérez votre webradio sur webradio.trackconnection.net",
    },
  ];

  customOptions: OwlOptions = {
    items: 2,
    autoWidth: true,
    loop: true,
    margin: 50,
    center: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    // animateOut: 'fadeOut'
  };

  app_name = environment.app_name;
  webradio_endpoint = environment.webradio_endpoint;
  boombox_invitation = environment.boombox_invitation;

  constructor(private seo: SEOService) {}

  ngOnInit(): void {
    // Set SEO tags
    this.seo.updateTitle("A propos");
    this.seo.injectCanonical();
  }

  getScreenshots(): Array<ScreenshotInterface> {
    return this.screenshots_ary;
  }
}
