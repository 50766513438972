<!-- MAIN BANNER -->
<section class="sub-bnr" parallax [config]="{ initialValue: 0, ratio: 0.7 }">
  <div class="position-center">
    <div class="container animate__fadeInDown animate__animated">
      <h1>A propos de nous</h1>
    </div>
  </div>

  <div class="container">
    <div class="card animate__animated animate__slideInLeft">
      <img
        src="/assets/images/around-the-world.png.webp"
        alt="Illustration d'une plate-forme accessible"
        height="210"
        width="210"
        class="illustration animate__fadeIn animate__animated"
      />
      <h2>Une plate-forme accessible</h2>

      <p>
        <strong>{{ app_name }}</strong> est une plate-forme indépendante mettant
        en avant les nouveaux talents qui souhaitent y participer. Nous vous
        fournissons plusieurs moyens de mettre en avant vos réalisations et de
        rester en contact avec vos premiers fans.
      </p>
      <p>
        Que vous soyez <em>beat maker</em>, <em>chanteur</em> ou bien
        <em>ingénieur du son</em>, chacun possède sa place. Vous êtes donc
        <strong>libre de vous exprimer</strong> en postant vos créations et en
        alimentant votre <strong>page personnelle</strong> afin que votre public
        puisse cerner au mieux votre univers.
      </p>
    </div>

    <div class="card right animate__animated animate__slideInRight">
      <img
        src="/assets/images/equalizer.png.webp"
        alt="Illustration d'une webradio"
        height="210"
        width="210"
        class="illustration animate__fadeIn animate__animated"
      />
      <h2>Une webradio à votre image</h2>

      <p>
        <strong>{{ app_name }}</strong> diffuse les sons de ses membres par le
        biais d'une webradio. Celle-ci est consultable depuis un
        <a
          href="{{ webradio_endpoint }}"
          rel="nofollow, noindex"
          target="_blank"
          >simple navigateur</a
        >
        <i class="fa fa-external-link"></i> ou via notre
        <a
          href="{{ boombox_invitation }}"
          rel="nofollow, noindex"
          target="_blank"
          >Boombox Discord</a
        >
        <i class="fa fa-external-link"></i> que chacun peut convier à son
        serveur.
      </p>
      <p>
        Mais ça ne s'arrête pas là. Nous mettons à votre disposition les outils
        nécessaires pour diffuser vos titres personnels sur une radio qui vous
        est dédiée. Chaque communauté peut donc disposer d'un flux propre à ses
        réalisations.
      </p>
    </div>
  </div>
</section>

<section class="padding-top-80 padding-bottom-60">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of getScreenshots()">
      <ng-template carouselSlide [id]="slide.id">
        <img [src]="slide.path" [alt]="slide.title" [title]="slide.title" />
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>

<section class="padding-top-80 padding-bottom-80">
  <div class="container">
    <div class="card animate__slideInLeft">
      <img
        src="/assets/images/discord.png.webp"
        height="160"
        width="160"
        alt="Logo de Discord"
        class="illustration"
      />

      <h2>Une ambiance conviviale</h2>
      <p>
        En plus de regrouper plusieurs talents au sein d'une même plate-forme,
        nous souhaitons offrir un lieu de rassemblement où chacun pourrait se
        sentir chez soi. Véritable lieu d'échange et d'assistance, nous
        encourageons nos membres à nous rejoindre sur
        <em>Discord</em> pour simplifier les échanges. Rien ne vous empêche de
        partager quelques anecdotes à vos camarades.
      </p>
      <p>
        Nous valorisons la <strong>bonne entente</strong> et la
        <strong>cohésion de groupe</strong> plus que la performance
        individuelle. Nous souhaitons tous progresser et prendre du plaisir par
        le biais de la création musicale.
      </p>
    </div>
  </div>
</section>
