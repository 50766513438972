import {
  ArtistInterface,
  ArtistService,
} from "src/app/core/services/artist.service";
import { environment } from "src/environments/environment";

import { Component, OnInit } from "@angular/core";

import { SEOService } from "../../core/services/seo.service";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.sass"],
})
export class HomePageComponent implements OnInit {
  artists: Array<ArtistInterface> = [];
  loading = false;

  constructor(private seo: SEOService, private artist_service: ArtistService) {}

  ngOnInit(): void {
    this.seo.updateTitle("Nos indés ont du talent");
    this.seo.injectCanonical();

    this.loading = true;
    this.update();
  }

  update(loading = true): Promise<void> {
    return new Promise((resolve, reject) => {
      let timeoutId = null;

      if (loading) {
        timeoutId = setTimeout(() => {
          this.loading = true;
        }, environment.loading_delay);
      }
      this.artist_service.list(1, "DDESC", 10).then(
        (output) => {
          if (loading) {
            clearTimeout(timeoutId);
            this.loading = false;
          }

          this.artists = output.artists;

          return resolve();
        },
        (err) => {
          if (loading) {
            clearTimeout(timeoutId);
            this.loading = false;
          }
          return reject(err);
        }
      );
    });
  }
}
