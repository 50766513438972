import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

export interface StationInterface {
  listen_url: string;
}

export interface NowPlayingHttpInterface {
  station: StationInterface;
  now_playing: NowPlayingInterface;
  playing_next: NowPlayingInterface;
}

export interface NowPlayingInterface {
  duration: number;
  elapsed: number;
  remaining: number;
  song: SongInterface;
}

export interface SongInterface {
  text: string;
  artist: string;
  title: string;
  album: string;
  genre: string;
  art: string;
}

@Injectable({
  providedIn: "root",
})
export class RadioService {
  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getListenURL(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.webradio_api_endpoint + "/nowplaying/trackconnection")
        .subscribe(
          (output: NowPlayingHttpInterface) => {
            return resolve(output.station.listen_url);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public nowPlaying(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.webradio_api_endpoint + "/nowplaying/trackconnection")
        .subscribe(
          (output: NowPlayingHttpInterface) => {
            return resolve(output.now_playing.song);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public nextPlaying(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.webradio_api_endpoint + "/nowplaying/trackconnection")
        .subscribe(
          (output: NowPlayingHttpInterface) => {
            return resolve(output.playing_next.song);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
