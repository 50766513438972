import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { ApiOutputInterface } from "../interfaces/api.interface";
import { ArtistInterface } from "./artist.service";
import { GenreInterface } from "./genre.service";
import { ImageInterface } from "./image.service";

export interface MediaInterface {
  uuid: string;
  name: string;
  description: string;
  showcased: boolean;
  cover: ImageInterface;
  genres: Array<GenreInterface> | Array<string>;
  owner: ArtistInterface;

  link_youtube: string;
  link_spotify: string;
  link_deezer: string;
  link_soundcloud: string;
  link_tidal: string;
  link_itunes: string;
  link_bandcamp: string;

  created_at: number;
}

export interface MediaHttpInterface extends ApiOutputInterface {
  media: MediaInterface;
}

interface MediumHttpInterface extends ApiOutputInterface {
  medium: Array<MediaInterface>;
  pages: number;
}

@Injectable({
  providedIn: "root",
})
export class MediaService {
  constructor(private http: HttpClient) {}

  public list(
    page = 1,
    sort = "DDESC",
    nb = 20,
    genre?: GenreInterface,
    artist?: ArtistInterface
  ): Promise<MediumHttpInterface> {
    return new Promise((resolve, reject) => {
      const params = {
        page: page.toString(),
        sort: sort,
        nb: nb.toString(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
      if (genre) params.genre = genre.uuid;
      if (artist) params.artist = artist.uuid;

      this.http
        .get(environment.api + "/public/medium", {
          params: params,
        })
        .subscribe(
          (output: MediumHttpInterface) => {
            return resolve(output);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public last(nb = 5): Promise<MediumHttpInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.api + "/public/medium/last", {
          params: { nb: nb.toString() },
        })
        .subscribe(
          (output: MediumHttpInterface) => {
            return resolve(output);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public showcased(uuid: string): Promise<MediaInterface> {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api + "/medium/showcased/" + uuid).subscribe(
        (output: MediaHttpInterface) => {
          return resolve(output.media);
        },
        (err) => {
          return reject(err);
        }
      );
    });
  }
}
