import { environment } from "src/environments/environment";

import { Component } from "@angular/core";

@Component({
  selector: "ui-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.sass"],
})
export class FooterComponent {
  app_name = environment.app_name;
  status_endpoint = environment.status_endpoint;
  boombox_topgg_endpoint = environment.boombox_topgg_endpoint;

  boombox_invitation = environment.boombox_invitation;

  discord_invitation = environment.discord_invitation;
  backoffice_endpoint = environment.backoffice_endpoint;

  constructor() {}

  ngOnInit(): void {}

  showRadio() {
    return environment.webradio_enable;
  }
}
