import {
  RadioService,
  SongInterface,
} from "src/app/core/services/radio.service";
import { environment } from "src/environments/environment";

import { Component, ElementRef, ViewChild } from "@angular/core";

@Component({
  selector: "ui-radio-player",
  templateUrl: "./radio-player.component.html",
  styleUrls: ["./radio-player.component.sass"],
})
export class RadioPlayerComponent {
  private current_artist = "Chargement";
  private current_title = "Chargement";

  @ViewChild("webplayer", { static: true }) private _audioRef: ElementRef;
  private audio: HTMLMediaElement;

  constructor(private radio: RadioService) {}

  ngOnInit(): void {
    if (!environment.webradio_enable) return;
    this.refreshCurrent();

    setInterval(() => {
      this.refreshCurrent();
    }, 1000 * 5);
  }

  public getCurrent(): any {
    return {
      artist: this.current_artist,
      title: this.current_title,
    };
  }

  public isPlaying(): boolean {
    return this.audio && !this.audio.paused;
  }

  public play(): void {
    if (this.audio) {
      if (this.audio.readyState >= 2) {
        this.audio.play();
      }
    }
  }

  public stop(): void {
    if (this.audio) {
      this.audio.pause();
    }
  }

  public ngAfterViewInit() {
    if (!environment.webradio_enable) return;

    this.audio = this._audioRef.nativeElement;
    this.radio.getListenURL().then(
      (url: string) => {
        this.audio.src = url;
      },
      () => {
        this.current_artist = "Erreur";
        this.current_title = "Erreur";
      }
    );
  }

  private refreshCurrent(): void {
    this.radio.nowPlaying().then(
      (current: SongInterface) => {
        this.current_title = current.title;
        this.current_artist = current.artist;
      },
      () => {
        this.current_artist = "Erreur";
        this.current_title = "Erreur";
      }
    );
  }
}
