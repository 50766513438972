import { MediaInterface } from "src/app/core/services/media.service";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { ApiOutputInterface } from "../interfaces/api.interface";
import { ArtistInterface } from "./artist.service";
import { GenreInterface } from "./genre.service";
import { ImageInterface } from "./image.service";

export interface PlaylistInterface {
  uuid: string;
  type: number;
  name: string;
  description: string;

  cover: ImageInterface;
  genres: Array<GenreInterface>;
  owner: ArtistInterface;
  medium: Array<MediaInterface>;

  link_youtube: string;
  link_spotify: string;
  link_deezer: string;
  link_soundcloud: string;
  link_itunes: string;
  link_tidal: string;
  link_bandcamp: string;

  is_showcased: boolean;
  is_visible: boolean;
  created_at: number;
}

export interface PlaylistHttpInterface extends ApiOutputInterface {
  playlist: PlaylistInterface;
}

interface PlaylistsHttpInterface extends ApiOutputInterface {
  playlists: Array<PlaylistInterface>;
  pages: number;
}

@Injectable({
  providedIn: "root",
})
export class PlaylistService {
  constructor(private http: HttpClient) {}

  public list(
    page = 1,
    nb = 20,
    sort = "DDESC",
    category?: string,
    type?: string,
    artist?: ArtistInterface,
    name?: string
  ): Promise<PlaylistsHttpInterface> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: any = {
        page: page.toString(),
        nb: nb.toString(),
        genre: category,
        sort: sort,
      };

      if (type) params.type = type;
      if (artist) params.artist = artist.uuid;
      if (name) params.name = name;

      this.http
        .get(environment.api + "/public/playlists", {
          params: params,
        })
        .subscribe(
          (output: PlaylistsHttpInterface) => {
            return resolve(output);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public fetch(playlist: string, artist: string): Promise<PlaylistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.api + "/public/playlists/fetch", {
          params: {
            artist: artist,
            playlist: playlist,
          },
        })
        .subscribe(
          (output: PlaylistHttpInterface) => {
            return resolve(output.playlist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
