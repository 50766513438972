import {
  MediaInterface,
  MediaService,
} from "src/app/core/services/media.service";
import { environment } from "src/environments/environment";

import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-section-last-tracks",
  templateUrl: "./section-last-tracks.component.html",
  styleUrls: ["./section-last-tracks.component.sass"],
})
export class SectionLastTracksComponent {
  medium: Array<MediaInterface> = [];
  loading = false;

  constructor(private media_service: MediaService, private router: Router) {}

  ngOnInit(): void {
    this.update();
  }

  goto(pseudo: string) {
    this.router.navigate([`artistes/${pseudo}`], { fragment: "titres" });
  }

  getCoverLink(uuid: string): string {
    return environment.api + "/files/" + uuid;
  }

  update(): Promise<void> {
    return new Promise(() => {
      this.loading = true;
      this.media_service.last(10).then(
        (output) => {
          this.medium = output.medium;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    });
  }
}
