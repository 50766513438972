<section class="padding-top-150 padding-bottom-100">
  <div class="container">
    <div class="icon">
      <img
        src="/assets/images/500.png.webp"
        alt="Logo d'erreur 500"
        width="150"
        height="150"
      />
    </div>

    <div class="message">
      <h3>500 Internal error</h3>
      <span
        >Oups, il semblerait que nous rencontrons quelques difficultés. Veuillez
        réssayer...</span
      >
    </div>
  </div>
</section>
