import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ArtistPreviewComponent } from "./artist-preview/artist-preview.component";
import { MapSidebarComponent } from "./artist-sidebar-preview.component";
import { GenreMenuComponent } from "./genre-menu/genre-menu.component";
import { HorizontalMenuComponent } from "./horizontal-menu/horizontal-menu.component";
import { LoaderComponent } from "./loader/loader.component";
import { MapComponent } from "./map/map.component";
import { MediaPreviewComponent } from "./media-preview/media-preview.component";
import { MediaShowcasedComponent } from "./media-showcased/media-showcased.component";
import { PlaylistPreviewComponent } from "./playlist-preview.component";
import { RadioPlayerComponent } from "./radio-player/radio-player.component";

@NgModule({
  declarations: [
    MediaPreviewComponent,
    ArtistPreviewComponent,
    MediaShowcasedComponent,
    PlaylistPreviewComponent,
    MapSidebarComponent,
    MapComponent,
    LoaderComponent,
    GenreMenuComponent,
    HorizontalMenuComponent,
    RadioPlayerComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    MediaPreviewComponent,
    ArtistPreviewComponent,
    MediaShowcasedComponent,
    PlaylistPreviewComponent,
    MapComponent,
    LoaderComponent,
    GenreMenuComponent,
    HorizontalMenuComponent,
    RadioPlayerComponent,
  ],
})
export class SharedModule {}
