import { LinkService } from "src/app/core/services/link.service";
import { MediaInterface } from "src/app/core/services/media.service";
import { PlaylistInterface } from "src/app/core/services/playlist.service";
import { environment } from "src/environments/environment";

import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "media-preview",
  templateUrl: "./media-preview.component.html",
  styleUrls: ["./media-preview.component.sass"],
})
export class MediaPreviewComponent implements OnInit {
  @Input()
  media: MediaInterface | PlaylistInterface;

  constructor(public link_service: LinkService, private router: Router) {}

  ngOnInit(): void {}

  openMedia(media: MediaInterface | PlaylistInterface): void {
    if (Object.prototype.hasOwnProperty.call(media, "type")) {
      this.router.navigate([
        `artistes/${this.media.owner.pseudo}/playlists/${this.media.name}`,
      ]);
    } else {
      const url = this.link_service.toLink(media as MediaInterface);
      if (url) window.open(url, "_blank");
    }
  }

  getImageLink(uuid: string): string {
    return environment.api + "/files/" + uuid;
  }
}
